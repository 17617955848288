import app_config from '@/config.json'
const { keyframe_diff } = app_config

export default {

	/**
	 * 当前字幕是否可以设置帧
	 */
	canSetKeyframeForSubtitle(subtitle) {
		return subtitle.end_time - subtitle.start_time > (keyframe_diff * 2 + 100)
	},

	/**
	 * 当前时间是否在帧内
	 * @param {*} time 当前时间
	 * @param {*} subtitle 字幕
	 * @param {*} obj 对象
	 * @param {*} state 前帧还是后帧 start or end
	 * @returns 
	 */
	isKeyframeActive(time, subtitle, obj, state) {
		if (subtitle && obj && obj.active && subtitle.objs.some(_ => _.id == obj.id)) {
			if (this.canSetKeyframeForSubtitle(subtitle) && time >= subtitle.start_time && time <= subtitle.end_time) {
				if (state == 'start') {
					return time - subtitle.start_time < keyframe_diff
				} else if (state == 'end') {
					return subtitle.end_time - time < keyframe_diff
				}
			}
		}
		return false
	},

	/**
	 * 帧内是否有数据
	 * @param {*} subtitle 字幕
	 * @param {*} obj 对象
	 * @param {*} state  前帧还是后帧 start or end
	 * @returns 
	 */
	isKeyframeHasData(subtitle, obj, state) {
		const subtitle_obj = subtitle.objs.find(_ => _.id == obj.id)
		if (subtitle_obj) {
			if (state == 'start') {
				return ['pos', 'flip'].some(_ => _ in subtitle_obj)
			} else if (state == 'end') {
				return 'end_pos' in subtitle_obj
			}
		}
		return false
	},

	/**
	 * 当前时间是否在字幕内
	 * @param {*} time 当前时间
	 * @param {*} subtitle 字幕
	 * @param {*} obj 对象
	 * @returns 
	 */
	isSubtitleActive(time, subtitle, obj) {
		return subtitle.start_time <= time && subtitle.end_time >= time && subtitle.objs.some(_ => _.id == obj.id && obj.active)
	},

	/**
	 * 查找当前时间距离最近的subtitle
	 * @param {*} subtitles 
	 * @param {*} current_time 
	 * @returns 
	 */
	findClosestSubtitle(subtitles, current_time) {
		let closest = null;
		let min_distance = Infinity;

		for (const item of subtitles.filter(_ => this.canSetKeyframeForSubtitle(_))) {
			if (current_time >= item.start_time && current_time <= item.end_time) {
				return item; // 如果 current_time 在某个区间内，直接返回该项
			}

			// 计算 current_time 到 start_time 和 end_time 的距离
			const distance_to_start = Math.abs(current_time - item.start_time);
			const distance_to_end = Math.abs(current_time - item.end_time);

			// 找出距离最近的时间段
			const min_item_distance = Math.min(distance_to_start, distance_to_end);
			if (min_item_distance < min_distance) {
				min_distance = min_item_distance;
				closest = item;
			}
		}

		return closest;
	}
}