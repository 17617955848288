import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'

const apiVersion = 'v010000'

const axiosInstance = axios.create({
    timeout: 10000,
})

axiosInstance.interceptors.request.use(
    config => {
        if (config.url.indexOf('/n/') === 0) {
            if (location.host == 'jtkh5.maijiawei.com') {
                config.baseURL = 'https://jtk.maijiawei.com'
            } else if (location.host == 'jtkh5-test.maijiawei.com') {
                config.baseURL = 'https://jtk-test.maijiawei.com'
            } else {
                // config.baseURL = 'http://192.168.1.2:3000'
                config.baseURL = 'http://127.0.0.1:3000'
                // config.baseURL = 'http://192.168.1.9:3000'
                config.baseURL = 'https://jtk.maijiawei.com'
            }
            config.url = config.url.substring(3)
        } else if (config.url.indexOf('/') === 0) {
            if (location.host == 'jtkh5.maijiawei.com') {
                config.baseURL = `https://jtk.tzhonghao.com/${apiVersion}`
            } else {
                config.baseURL = `https://jtktest.tzhonghao.com/${apiVersion}`
            }
        }
        config.headers['Authorization'] = store.state.vuex_token

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    response => {
        switch (response.data.code) {
            case 0://正常返回
                return response.data.data
            case 100://登陆
                store.commit('mStore', { name: 'vuex_token', value: '' })
            case 101://toast
                Toast({ message: response.data.msg, position: 'top' });
            default:
                return Promise.reject(response.data)
        }

    },
    error => {
        Toast({ message: '系统繁忙', position: 'top' });
        return Promise.reject(error)
    }
)

export default axiosInstance
